import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "@components/shared/seo";

const CustomersSEO = () => {
  const {
    sanityPartnersPage: { seo: data },
  } = useStaticQuery(graphql`
    query PartnersSEOQuery {
      sanityPartnersPage {
        seo {
          pageTitle
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  return (
    // <SEO
    //   title={data.pageTitle}
    //   description={data.pageDescription}
    //   keywords={data.pageKeyWords}
    // />
    <SEO
      overwriteTitle={data.pageTitle}
      overwriteDescription={data.pageDescription}
    />
  );
};

export default CustomersSEO;
