import { useSeoQuery } from "@api";
import { Maybe, SanityLocationObject, SanitySeo } from "@graphql-types";
import { setBaseUrl } from "@util/helper";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug?: string;
  children?: ReactNode;
  alternateRegions?: (Maybe<SanityLocationObject> | undefined)[];
}

const SEO = (props: Props) => {
  const { sanityDefaultSeo } = useSeoQuery();

  let urlString = "https://www.celohealth.com";

  if (props.slug) {
    urlString = urlString + props.slug;
  }

  return (
      <Helmet
        title={
          props.overwriteTitle ||
          props.seoData?.pageTitle ||
          sanityDefaultSeo?.seo?.pageTitle ||
          "Default Title"
        }
        defer={false}
      >
        <link rel="preconnect" href="https://www.recaptcha.net" />
        <link rel="canonical" href={`${urlString}`} />


        {props.alternateRegions &&
          props.alternateRegions.length > 0 &&
          props.alternateRegions.map(alt => {
            if (alt == null) {
              return null;
            }

            return (
              <link
                key={alt.iban}
                rel="alternate"
                hrefLang={alt.hrefLang as string}
                href={setBaseUrl(props.slug, alt.iban as string)}
              />
            );
          })}

        <meta
          name="description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            sanityDefaultSeo?.seo?.pageDescription ||
            "Default Page Description"
          }
        />
        <meta
          name="keywords"
          content={
            props.seoData?.pageKeyWords ||
            sanityDefaultSeo?.seo?.pageKeyWords ||
            "Default Keywords"
          }
        />
        <html lang="en" />

        <meta
          property="og:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            sanityDefaultSeo?.seo?.pageTitle ||
            "Default Page Title"
          }
        />
        <meta property="og:type" content={props.post ? `article` : `website`} />
        <meta property="og:url" content={urlString} />
        <meta
          property="og:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            sanityDefaultSeo?.seo?.ogImage?.asset?.url ||
            "Default Content"
          }
        />
        <meta
          property="og:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            sanityDefaultSeo?.seo?.pageDescription ||
            "Default Content"
          }
        />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            sanityDefaultSeo?.seo?.pageDescription ||
            "Default Twitter"
          }
        />
        <meta
          name="twitter:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            sanityDefaultSeo?.seo?.pageTitle ||
            "Default Twitter Title"
          }
        />
        <meta
          name="twitter:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            sanityDefaultSeo?.seo?.ogImage?.asset?.url ||
            "Default Twitter Image"
          }
        />
        <meta property="twitter:site" content={urlString} />
        <meta
          name="twitter:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            sanityDefaultSeo?.seo?.pageDescription ||
            "Default Page Description"
          }
        />
        <script src="https://assets.adobedtm.com/175f7caa2b90/b8a3209e9dee/launch-6d0f2e4e57eb.min.js" async></script>
      </Helmet>
  );
};

export default SEO;
