import React from "react";
import { v4 as uuid } from "uuid";
import { Maybe, SanityPartnersIntro } from "@graphql-types";
import styled from "styled-components";
import Image from "../../shared/sub/image";

interface Props {
  data: Maybe<SanityPartnersIntro> | undefined;
}

const StyledPageIntro = styled.div`
  #customers_page_intro {
    margin-bottom: 5rem;
  }
  #customers_page_intro .container {
    border-radius: 8px;
    overflow: hidden;
    width: 80%;
    box-shadow: 0 0 10px rgba(33, 33, 33, 0.1);
  }
  @media screen and (max-width: 600px) {
    #customers_page_intro .container {
      margin-top: 5rem;
    }
  }
  #customers_page_intro .container .typical-customers-wrapper {
    padding: 2rem 10rem;
  }
  @media screen and (max-width: 1650px) {
    #customers_page_intro .container .typical-customers-wrapper {
      padding-left: 120px !important;
      padding-right: 120px !important;
    }
  }
  @media screen and (max-width: 1650px) {
    #customers_page_intro .container .typical-customers-wrapper {
      padding-left: 90px !important;
      padding-right: 90px !important;
    }
  }
  @media screen and (max-width: 980px) {
    #customers_page_intro .container .typical-customers-wrapper {
      padding: 6rem 3rem;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  #customers_page_intro .container .typical-customers-wrapper h2 {
    margin-bottom: 0px;
    font-size: 21px;
  }
  @media screen and (max-width: 1000px) {
    #customers_page_intro .container .typical-customers-wrapper h2 {
      text-align: center;
    }
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
  }
  @media screen and (max-width: 1000px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 740px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers {
      justify-content: space-evenly;
    }
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers
    .typical-customer {
    max-width: 24rem;
    margin: 4rem 1rem;
  }
  @media screen and (max-width: 900px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers
      .typical-customer {
      margin: 3rem 0;
    }
  }
  @media screen and (max-width: 600px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers
      .typical-customer {
      margin: 10px auto;
    }
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers
    .typical-customer
    .gatsby-image-wrapper {
    height: 15rem !important;
    min-width: 15rem !important;
    margin-bottom: 4rem;
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers
    .typical-customer
    .gatsby-image-wrapper
    img {
    object-fit: contain !important;
  }
  @media screen and (max-width: 600px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers
      .typical-customer
      .gatsby-image-wrapper {
      margin-bottom: 20px;
    }
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers
    .typical-customer
    h4 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
    height: 10px;
  }
  @media screen and (max-width: 600px) {
    #customers_page_intro
      .container
      .typical-customers-wrapper
      .typical-customers
      .typical-customer
      h4 {
      height: auto;
    }
  }
  #customers_page_intro
    .container
    .typical-customers-wrapper
    .typical-customers
    .typical-customer
    p {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
  }
`;

const PartnersIntro = (props: Props) => {
  const { data } = props;

  if (data == null) return null;

  const { partnerTypes, title } = data;
  return (
    <StyledPageIntro>
      <div id="customers_page_intro">
        <div className="container">
          <div className="typical-customers-wrapper">
            <h2>{title}</h2>
            {partnerTypes && (
              <div className="typical-customers">
                {partnerTypes.map(customerType => {
                  if (customerType == null) return null;

                  const { image, title, description } = customerType;

                  return (
                    <div key={uuid()} className="typical-customer">
                      {image && <Image data={image} />}
                      <h4>{title}</h4>
                      <p>{description}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledPageIntro>
  );
};

export default PartnersIntro;
