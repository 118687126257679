import { GridContainer, Container } from "./../../util/standard";
import styled from "styled-components";
import Image from "@components/shared/sub/image";

import {
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_LANDSCAPE,
  colors,
} from "@util/constants";
import { P } from "@util/standard";

export const GridWrapperContainer = styled(GridContainer)<{
  featuredHero?: boolean;
  featuredBlog?: boolean;
}>`
  ${({ featuredHero }) => !featuredHero && `height: 500px; `};
  ${({ featuredBlog }) =>
    featuredBlog &&
    ` border-radius: 25px; background-color: rgba(128, 212, 254, 0.25);`};

  width: 80%;
  margin: auto;

  @media only screen and (max-width: ${TABLET_LANDSCAPE}px) {
    grid-gap: 30px;
    margin-bottom: 40px;
    height: auto;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);

    grid-gap: 0px;
    margin-bottom: 40px;
    ${({ featuredBlog }) =>
      featuredBlog &&
      `max-width: 500px; margin: 40px auto; border-radius: 25px;`};
  }
`;

export const HeroTextContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 350px;
    margin: 50px auto 0px auto;
  }
`;

export const ImageWrapper = styled.div<{
  noDownloadLinks: boolean;
  featuredHero?: boolean;
  roundedCorners?: boolean;
  isGif?: boolean;
  fullHeight?: boolean;
  noBottomMargin?: boolean;
  featureBlogPost?: boolean;
  noMaxDimensions?: boolean;
}>`
  position: relative;
  ${({ roundedCorners }) =>
    roundedCorners && `border-radius: 7px; overflow:hidden;`};

  ${({ fullHeight }) => !!fullHeight ? `height: 100%;` : `height: inherit`};

  ${({ featureBlogPost }) =>
    featureBlogPost &&
    `border-top-right-radius: 25px; border-bottom-right-radius: 25px; overflow:hidden;`};

  width: 100%;
  margin: auto;
  ${({noMaxDimensions}) => !noMaxDimensions && `max-width: 500px;`}
  ${({noMaxDimensions}) => !noMaxDimensions && `max-height: 500px;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ noDownloadLinks }) =>
      noDownloadLinks && `margin-top: 30px; margin-bottom: 30px;`};
    ${({ featureBlogPost }) =>
      featureBlogPost &&
      `border-top-right-radius: 0px; border-bottom-right-radius: 25px; border-bottom-left-radius: 25px; overflow:hidden; height: 300px; margin-bottom: 0px;`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    ${({ noBottomMargin }) => noBottomMargin && `margin-bottom: 0px`};
  }
`;

export const StyledGif = styled.img`
  width: auto;
  height: 100% !important;
  display: block;
  margin: auto;

  max-width: 500px;
  max-height: 500px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: auto;
  }
`;

export const StyledImg = styled(Image)`
  width: 100% !important;
  height: 100% !important;

  max-width: 500px;
  max-height: 500px;

  img {
    object-fit: contain !important; // !important to override the element style
  }
`;

export const BlockContentContainer = styled.div<{
  featuredHero?: boolean;
}>`
  padding: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 100px;

  ${({ featuredHero }) =>
    featuredHero &&
    `display: flex; align-items: center; padding: 100px 0px 0px 100px; height: auto;`};

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
    font-family: "header";
    color: ${colors.charcoal};
  }
  @media only screen and (max-width: ${TABLET_LANDSCAPE}px) {
    padding: 50px 0px 0px 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0px auto 0px auto;
    padding: 0px;
    text-align: center;

    h1 {
      display: inline;
      margin-left: 0px !important;
    }
    p {
      text-align: center;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  padding-left: 100px;
  padding-bottom: 50px;
  p {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: "header";
  }

  @media only screen and (max-width: ${TABLET_LANDSCAPE}px) {
    padding-left: 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    p {
      text-align: left;
      font-family: "header";
      line-height: 19px;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    p {
      text-align: center !important;
    }
  }
`;

export const DownloadText = styled(P)<{ desktop?: boolean }>`
  font-size: 15px;
  width: 80%;
  color: ${colors.lightGrey};
  ${({ desktop }) => (desktop ? `display: block;` : `display: none;`)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 15px;
    text-align: center !important;
    line-height: 12px;
    ${({ desktop }) => (desktop ? `display: none;` : `display: block;`)};
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    margin: auto;
    margin-top: 10px !important;
  }
`;

export const DownloadButton = styled.img`
  width: 148px;
  cursor: pointer;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 127px;
    margin: auto;
  }

  &:hover {
    transform: scale(1.05);
  }

  transition: all 0.2s;
`;
